*, *:before, *:after {
    box-sizing: border-box;
}
:root {
    --primary: #917FEE;
    --secondary: #F58349;
}

body {
    font-family: 'gt-america';
    background-color: #fff;
    margin: 0;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
}

.container {
    margin: 0 auto;
}

.generatorCols {
    
}
.generatorCol {
    background-color: var(--secondary);
    padding: 50px 0 50px;
    display: flex;
    justify-content: center;
}
.generatorColAlt {
    background-color: var(--primary);
    padding: 80px 0 50px;
}

.formRow + .formRow {
    margin-top: 24px;
}

.title {
    font-family: 'caslon';
    font-weight: 400;
    text-transform: uppercase;
    font-size: 42px;
    line-height: 0.87;
    max-width: 440px;
    margin-bottom: 0.4em;
}

.subtitle {
    margin-bottom: 12px;
    display: block;
    font-weight: bold;
    font-size: 15px;
}

.buttonPrimary {
    background-color: #917FEE;
    color: #fff !important;
}

.buttonShare {
    display: inline-flex !important;
    margin-right: 13px;
    margin-bottom: 13px;
}

.buttonShare svg {
    margin-right: 10px;
    margin-left: 0 !important;
    z-index: 2;
}

.button {
    align-items: center;
    color: var(--secondary);
    display: flex;
    font-size: 1rem;
    text-decoration: none;
    justify-content: center;
    padding: 1em 1.8em 1.2em;
    position: relative;
    appearance: none;
    background-color: transparent;
    border: none;
    font: inherit;
    cursor: pointer;
}
.button:before {
    background: #fff;
    border-radius: 1em;
    content: "";
    height: 100%;
    height: 110%;
    position: absolute;
    transform: scale(0.92, 0.9);
    transition: 0.32s cubic-bezier(0.8, -0.54, 0.22, 1.46);
    width: 100%;
    width: 108%;
    z-index: 1;
}
.buttonPrimary.button:before {
    background-color: #917FEE;
}
.button:visited {
    color: var(--secondary);
}

.disableBtn {
    opacity: 0.6;
}
.error {
    margin-bottom: 10px;
    display: block;
    font-style: italic;
}

@media (max-width: 767px) {
    .hideBtnIcon svg {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .button {
        font-size: 1rem;
    }
}
.button span {
    position: relative;
    z-index: 2;
}
.button span + span {
    color: inherit;
    margin-left: 1em;
}
.button .icon svg {
    display: block;
    height: 1em;
    width: 1em;
}

.button .icon.down {
    overflow: hidden;
    padding: 3px 0;
    position: relative;
    top: 0.08em;
}
.button .icon.down svg {
    transition: 0.38s cubic-bezier(0.8, -0.54, 0.22, 1.46);
}
.button .icon.down svg:first-child {
    left: 0;
    position: absolute;
    transform: translateX(-150%);
}
@media (hover: hover) {
    .button:hover {
        color: var(--secondary);
    }
    .button:hover:before {
        transform: scale(1);
    }
    .button:hover .icon.down svg:first-child {
        transform: translateX(5%);
    }
    .button:hover .icon.down svg:last-child {
        transform: translateX(115%);
    }
}
.buttonAlt {
    appearance: none;
    background-color: transparent;
    padding: 0;
    border: none;
    color: #fff;
    text-decoration: underline;
    font-family: 'gt-america';
    font-size: 14px;
    cursor: pointer;
    transition: 0.12s ease-in-out;
}

.buttonAlt:hover {
    opacity: 0.8;
}

.section {
    margin: 45px 0;
}

.modal {
    background-color: rgba(145, 127, 238, 0.85);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.12s ease-in-out;
}

.modal.modalIsOpen {
    opacity: 1;
    pointer-events: all;
}

.modal .button, .modal .buttonAlt {
    margin: 0 auto;
}
.modal .button {
    display: flex;
    width: 100%;
    margin-top: 40px;
}
.modal .buttonAlt {
    display: table;
}

.modalInner {
    background-color: #fff;
    max-width: 652px;
    width: 100%;
    padding: 40px 25px;
    border-radius: 40px;
    text-align: center;
}

.modalFrame {
    width: 100%;
}

.logo {
    position: absolute;
    z-index: 2;
    top: 17px;
    left: 17px;
}

.logo svg {
    width: 114px;
}

@media(min-width: 767px) {
    .logo {
        left: 35px;
        top: 35px;
        transition: 0.12s ease-in-out;
        position: fixed;
    }
    .logo:hover {
        opacity: 0.7;
    }
    .logo svg {
        width: auto;
    }
    .generatorCols {
        display: flex;
    }
    .generatorCol {
        width: 50%;
        padding: 85px 50px;
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: space-between;
    }
    .generatorColAlt {
        height: 100vh;
        position: sticky;
        top: 0;
        align-items: center;
    }
    .modalInner {
        padding: 50px 65px;
        text-align: left;
        line-height: 1.5;
        font-size: 18px;
    }
    .modalButtons {
        display: flex;
        align-items: center;
        margin-top: 30px;

    }
    .modalButtons .button, .modalButtons .buttonAlt {
        margin: 0;
        width: auto;
    }
    .title {
        font-size: 54px;
    }
}

@media(min-width: 767px) {
}

.section + .section {
    margin-top: 50px;
}

.row {
    display: flex;
    margin: 0 -17px;
    flex-direction: column;
}

.content {
    margin-bottom: 30px;
}

@media(min-width: 767px) {
    .row {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    .content p {
        font-size: 18px;
    }
}

.content p {
    font-size: 16px;
    line-height: 145%;
}

@media(min-width: 767px) {
    .content p {
        font-size: 18px;
    }
}

.form {
    flex: 1;
    padding: 0 17px;
    max-width: 600px;
}

@media(min-width: 767px) {
    .form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}

.preview {
    padding: 0 17px;
}
@media(min-width: 767px) {
    .preview {
        margin-top: 0px;
        position: sticky;
        top: 75px;
    }
}

.render {}

.hideMobile { display: none; }
@media(min-width: 767px) {
    .hideMobile { display: block; }
}

.hideDesktop { display: block; }
@media(min-width: 767px) {
    .hideDesktop { display: none; }
}


@font-face {
    font-family: 'caslon';
    src: url('./fonts/CaslonSansShaded-Regular.woff2') format('woff2'),
        url('./fonts/CaslonSansShaded-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'gt-america';
    src: url('./fonts/GT-America-Extended-Regular.woff2') format('woff2'),
        url('./fonts/GT-America-Extended-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'gt-america';
    src: url('./fonts/GT-America-Extended-Bold.woff2') format('woff2'),
        url('./fonts/GT-America-Extended-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


