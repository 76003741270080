.title {
    font-size: 24px;
    text-align: center;
    display: block;
}

@media(min-width: 768px) {
    .title {
        font-size: 28px;
    }
}