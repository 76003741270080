.preview {
    /* pointer-events: none;
    user-select: none; */
}

.previewSelector {
    
}

.previewRenderInner {
    background-color: var(--primary);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.previewSelector h3 {
    margin-top: 1.25em;
    margin-bottom: .75em;
}

.flipTrigger {
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-family: inherit;
    display: table;
    margin: 28px auto 0;
    cursor: pointer;
    font-size: 15px;
    transition: 0.12s ease-in-out;
}



.flipTrigger span {
    text-decoration: underline;
}

.flipTrigger svg {
    vertical-align: middle;
    margin-left: 6px;
    transition: 0.7s ease-in-out;
}

.flipTrigger svg.svgRotated {
    transform: rotate(180deg);
}

@media(min-width: 767px) {
    .flipTrigger {
        margin-top: 60px;
        font-size: 16px;
    }
    .flipTrigger:hover {
        opacity: 0.7;
    }
}