.box {
    /* --theme: #C75975; */
    
    transition: 150ms;
    border: 2px solid #fff;
    border-radius: 3px;
    font-size: 1em;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.box svg {
    margin-left: 6px;
    margin-right: -5px;
}

.options {
    display: flex;
    margin: -7px;
    align-items: flex-start;
}

@media(min-width: 767px) {
    .options {
        flex-direction: row;
        white-space: nowrap;
    }
}

.option {
    position: relative;
    margin: 7px;
}

.option input[type="radio"] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    z-index: -1;
}

.active .box, .box:hover {
    background: #162A37;
    color: #FFF;
}

.active .box svg path, .box:hover svg path {
    fill: #FFF;
    stroke: #FFF;
}
.coloredOption .box {
    background-color: var(--theme);
}
.active.coloredOption .box, .coloredOption:hover .box {
    border-color: rgba(255,255,255,0.5);
}